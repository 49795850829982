export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'الرئيسية',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'العملاء',
        to: '/clients',
        icon: 'cil-people'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'المحامين',
        to: '/lawyers',
        icon: 'cib-open-collective'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'الحجوزات',
        to: '/bookings',
        icon: 'cil-calendar-check'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'الاستشارات',
        to: '/consultations',
        icon: 'cil-speech'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'طلبات الخدمات',
        to: '/service-requests',
        icon: 'cil-lightbulb'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'المقالات',
        to: '/articles',
        icon: 'cib-blogger'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'الاسئلة',
        to: '/questions',
        icon: 'cil-info'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'الاجابات',
        to: '/answers',
        icon: 'cil-info'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'التصنيفات',
        to: '/categories',
        icon: 'cil-list'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'الخدمات',
        to: '/services',
        icon: 'cil-list'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'الدول',
        to: '/countries',
        icon: 'cil-align-right'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'المحافظات',
        to: '/states',
        icon: 'cil-location-pin'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'أعضاء الا دارة',
        to: '/admins',
        icon: 'cil-star'
      }
    ]
  }
]