<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
    </template>
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>الطلبات الجديدة</strong>
    </CDropdownHeader>
    <CDropdownItem href="/orders/1234">
      <CIcon name="cil-user" /> الطلب رقم 1234
    </CDropdownItem>

  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {}
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>